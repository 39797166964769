// extracted by mini-css-extract-plugin
export var backgroundAnimation = "client-candidate-search-module--backgroundAnimation--e2e3c";
export var buttonSubtext = "client-candidate-search-module--button-subtext--15662";
export var contactBox = "client-candidate-search-module--contact-box--b75fa";
export var container = "client-candidate-search-module--container--49e50";
export var grid = "client-candidate-search-module--grid--ee867";
export var header = "client-candidate-search-module--header--2dd63";
export var loader = "client-candidate-search-module--loader--80080";
export var loading = "client-candidate-search-module--loading--dc6c3";
export var sectionBox = "client-candidate-search-module--section-box--2d5cb";
export var tableGrid = "client-candidate-search-module--table-grid--8dd67";
export var title = "client-candidate-search-module--title--5e356";