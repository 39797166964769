import React, { useState, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Formik } from 'formik';

import {
    grid,
    sectionBox,
    loader,
    loading,
    container,
    title,
    header,
    contactBox,
    tableGrid,
    buttonSubtext,
} from './client-candidate-search.module.scss';
import { ISection } from '../../models/section.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { TLocale } from '../../locale';
import { IClientCandidates } from '../../models/client-advertisement.model';
import { IFilter, IFilters } from '../../models/filter.model';
import { ICheckboxRawProps } from '../atoms/checkbox-raw';
import { ISearchInputProps } from '../molecules/search-input';
import { breakpoints } from '../../config/breakpoints';
import useTranslations from '../../hooks/use-translations';
import { useList } from '../../hooks/use-list';
import { useClient } from '../../hooks/use-client';
import useMediaQuery from '../../hooks/use-media-query';
import { getFilterParamOptionsLabels } from '../../utils/get-filter-param-options-labels';
import { getCandidatesAvailableToContact } from '../../utils/get-available-candidates';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { useClientPermission } from '../../hooks/use-client-permission';
import { useNoPermissionModal } from '../../hooks/use-no-permission-modal';

import Loader from '../atoms/loader';
import Button from '../atoms/button';
import Tooltip from '../atoms/tooltip';
import Table, { ITableProps } from '../organisms/table';
import Section from '../hoc/section';
import Filter from '../molecules/filter';
import DashboardHeader from '../molecules/dashboard-header';
import HandleFormikChange from '../hoc/handle-formik-change';

interface IClientCandidateSearchProps {
    section: ISection;
    TitleTag?: React.ElementType;
}

interface IClientCandidateSearchQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname' | 'type'>>;
}

const ClientCandidateSearch: React.FC<IClientCandidateSearchProps> = ({
    section,
    TitleTag = 'h2',
}) => {
    const { style, css, sectionId } = section;
    const t = useTranslations('ClientCandidateSearch');
    const client = useClient();
    const canContact = useClientPermission(['can-contact']);
    const { addNoPermissionPlanModal } = useNoPermissionModal();
    const { allPage } = useStaticQuery<IClientCandidateSearchQueryResult>(query);
    const candidatePreviewPage = usePagePathname(allPage, 'client-candidate-preview');
    const candidateContactPage = usePagePathname(allPage, 'client-candidate-contact');

    const [selectedCandidateIds, setSelectedCandidateIds] = useState<Array<number | string>>([]);
    const [isCantSearchModalShown, setIsCantSearchModalShown] = useState(false);

    const {
        status,
        isInitialLoading,
        items,
        paginationPaths,
        filters,
        handleChange,
        handleSearch,
        values,
        sort,
        pagination,
        initialSearchValue,
    } = useList<IClientCandidates>({
        endpoint: `/candidates`,
        token: client.data?.token.token,
        perPage: 5,
        paramsWhiteList: ['id'],
        apiKeywords: { search: 'phrase' },
        initialParams: { sort: '-score' },
    });

    const prevSearchRef = useRef(initialSearchValue);
    const { positionFilter, newFilters } = getNewFilters(filters, t);
    const isMobile = useMediaQuery(breakpoints.iPad);

    const handleToggleCandidate: ICheckboxRawProps['onChange'] = (isChecked, value) => {
        if (isChecked) {
            setSelectedCandidateIds((prev) => [...prev, value]);
        } else {
            setSelectedCandidateIds((prev) => prev.filter((id) => id !== value));
        }
    };

    const handleToggleAllCandidates: ICheckboxRawProps['onChange'] = (isChecked) => {
        const candidateIds = getCandidatesAvailableToContact(items).map((item) => item.id) as Array<
            string | number
        >;
        if (isChecked) {
            setSelectedCandidateIds((prev) => [...prev, ...candidateIds]);
        } else {
            setSelectedCandidateIds((prev) => prev.filter((id) => !candidateIds.includes(id)));
        }
    };

    const handleSearchChange: ISearchInputProps['onSearch'] = (value) => {
        if (canContact) {
            handleSearch(value);
        } else if (prevSearchRef.current !== value && !isCantSearchModalShown) {
            addNoPermissionPlanModal();
            setIsCantSearchModalShown(true);
        }
        prevSearchRef.current = value;
    };

    return (
        <Section
            className={sectionBox}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {isInitialLoading && <Loader className={loader} />}
            {!isInitialLoading && (
                <Formik onSubmit={() => {}} initialValues={values || {}} enableReinitialize={true}>
                    {(formik) => (
                        <div className={container}>
                            <HandleFormikChange onChange={handleChange} />
                            <div className={`${header} ${status === 'loading' ? loading : ''}`}>
                                <DashboardHeader className={title} TitleTag={TitleTag}>
                                    {t.title}
                                </DashboardHeader>
                                {positionFilter && !isMobile && <Filter filter={positionFilter} />}
                                <Tooltip>{t.hint}</Tooltip>
                            </div>
                            <div className={contactBox}>
                                <Button
                                    {...(canContact
                                        ? {
                                              as: 'link',
                                              to: `${candidateContactPage}?candidatesIds=${selectedCandidateIds.join(
                                                  ','
                                              )}`,
                                          }
                                        : {
                                              type: 'button',
                                              onClick: addNoPermissionPlanModal,
                                          })}
                                    isDisabled={selectedCandidateIds.length === 0}
                                >
                                    {t.contactAll}{' '}
                                    {selectedCandidateIds.length > 0 &&
                                        `(${selectedCandidateIds.length})`}
                                </Button>
                                <Tooltip>{t.contactHint}</Tooltip>
                            </div>
                            <Table
                                tableClassName={tableGrid}
                                headerCells={getHeaderCells(
                                    t,
                                    selectedCandidateIds,
                                    items,
                                    handleToggleAllCandidates
                                )}
                                rows={getCandidateRows(
                                    t,
                                    items,
                                    candidatePreviewPage,
                                    candidateContactPage,
                                    selectedCandidateIds,
                                    handleToggleCandidate,
                                    filters,
                                    canContact,
                                    addNoPermissionPlanModal
                                )}
                                status={status}
                                totalCount={pagination?.totalCount || 0}
                                paginationPaths={paginationPaths}
                                searchInputProps={{
                                    onSearch: handleSearchChange,
                                    placeholder: t.search,
                                    initialValue: initialSearchValue,
                                }}
                                filters={isMobile ? filters : newFilters}
                                sort={sort}
                                formik={formik}
                            />
                        </div>
                    )}
                </Formik>
            )}
        </Section>
    );
};

const getNewFilters = (
    filters: IFilters | null | undefined,
    t: TLocale['ClientCandidateSearch']
) => {
    const newFilters = { ...filters };
    let positionFilter: IFilter | null | undefined = undefined;

    if (filters) {
        positionFilter = Object.values(filters).find((filter) => filter.paramName === 'position');
        const positionIndex = Object.keys(filters).find((key) => filters[key] === positionFilter);

        if (positionFilter && positionIndex) {
            positionFilter.label = t.position;
            delete newFilters[positionIndex];
        }
    }
    return {
        positionFilter,
        newFilters,
    };
};

function getHeaderCells(
    t: TLocale['ClientCandidateSearch'],
    selectedCandidateIds: Array<number | string>,
    candidates: IClientCandidates[],
    onToggleAllCandidates: ICheckboxRawProps['onChange']
): ITableProps['headerCells'] {
    const availableCandidates = getCandidatesAvailableToContact(candidates);
    const isChecked =
        availableCandidates.length > 0 &&
        availableCandidates.every((candidate) => selectedCandidateIds.includes(candidate.id));
    return [
        {
            type: 'checkbox-raw',
            checkboxRawProps: {
                name: 'checkbox-all',
                value: 'all',
                isChecked: isChecked,
                onChange: onToggleAllCandidates,
                children: t.selectAll,
                disabled: availableCandidates.length === 0,
            },
        },
        { label: t.name },
        { label: t.lastname },
        { label: t.workTime },
        { label: t.shift },
        { label: t.employment },
        { label: t.permits },
        { label: t.location },
        { label: t.workPermit },
        { label: t.match, field: 'score' },
        { label: '' },
    ];
}

function getCandidateRows(
    t: TLocale['ClientCandidateSearch'],
    candidates: IClientCandidates[],
    candidatePreviewPage: string,
    candidateContactPage: string,
    selectedCandidateIds: Array<number | string>,
    onCandidateToggle: ICheckboxRawProps['onChange'],
    filters: IFilters | null | undefined,
    canContactPermission: boolean,
    addNoPermissionPlanModal: () => void
): ITableProps['rows'] {
    return candidates.map((candidate) => {
        const canContact = new Date().getTime() > candidate.nextContactAt * 1000;
        return [
            {
                type: 'checkbox-raw',
                checkboxRawProps: {
                    name: `checkbox-${candidate.id}`,
                    value: candidate.id,
                    onChange: onCandidateToggle,
                    disabled: !canContact,
                    isChecked: selectedCandidateIds.some(
                        (candidateId) => candidate.id === candidateId
                    ),
                },
            },
            {
                type: 'data',
                label: t.name,
                value: candidate.firstName,
                valueStyle: (!candidate.isBought && 'blurred') || undefined,
            },
            {
                type: 'data',
                label: t.lastname,
                value: candidate.lastName,
                valueStyle: (!candidate.isBought && 'blurred') || undefined,
            },
            {
                type: 'data',
                label: t.workTime,
                value: getFilterParamOptionsLabels(
                    'workingTime',
                    candidate.workingTime,
                    filters
                ).join(' / '),
            },
            {
                type: 'data',
                label: t.shift,
                value: getFilterParamOptionsLabels('workShift', candidate.workShift, filters).join(
                    ' / '
                ),
            },
            {
                type: 'data',
                label: t.employment,
                value: getFilterParamOptionsLabels(
                    'cooperationForm',
                    candidate.cooperationForm,
                    filters
                ).join(' / '),
            },
            {
                type: 'data',
                label: t.permits,
                value: getFilterParamOptionsLabels('licenses', candidate.licenses, filters).join(
                    ' / '
                ),
            },
            {
                type: 'data',
                label: t.location,
                value: candidate.city,
            },
            {
                type: 'data',
                label: t.workPermit,
                value: getFilterParamOptionsLabels(
                    'workPermit',
                    [candidate.workPermit],
                    filters
                ).join(' / '),
            },
            {
                type: 'data',
                label: t.match,
                value: `${candidate.score || 0}%`,
                valueStyle: 'accent',
            },
            {
                type: 'action',
                actions: [
                    candidate.isBought
                        ? {
                              as: 'link',
                              stylePreset: 'primary',
                              size: 'medium',
                              to: `${candidatePreviewPage}?id=${candidate.id}`,
                              children: t.details,
                          }
                        : null,
                    {
                        ...(canContactPermission
                            ? {
                                  as: 'link',
                                  to: `${candidateContactPage}?candidatesIds=${candidate.id}`,
                              }
                            : {
                                  type: 'button',
                                  onClick: addNoPermissionPlanModal,
                              }),
                        stylePreset: 'secondary',
                        size: 'medium',
                        disabled: !canContact,
                        children: canContact ? (
                            t.contact
                        ) : (
                            <span>
                                {t.invitedButton}
                                <br />
                                <span className={buttonSubtext}>
                                    ({t.invitedSubtext} {candidate.nextContactEnabledIn})
                                </span>
                            </span>
                        ),
                    },
                ],
            },
        ];
    });
}

export const query = graphql`
    query {
        allPage(
            filter: { type: { in: ["client-candidate-preview", "client-candidate-contact"] } }
        ) {
            edges {
                node {
                    pathname
                    type
                    locale
                }
            }
        }
    }
`;

export default ClientCandidateSearch;
