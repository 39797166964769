import { IFilters } from '../models/filter.model';

export function getFilterParamOptionsLabels(
    paramName: string,
    optionValues: Array<string | number>,
    filters: IFilters | null | undefined
) {
    if (!filters) return [];
    const filter = Object.values(filters).find((filter) => filter.paramName === paramName);
    if (!filter) return [];
    return optionValues
        .map((optionValue) => {
            return filter.options.find(
                (option) => option.value.toString() === optionValue.toString()
            )?.label;
        })
        .filter((label) => typeof label === 'string') as string[];
}
